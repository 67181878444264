<template>
  <div class="searchsite">
    <van-search v-model="value" show-action placeholder="输入站点名称/运营人员/站长" clearable maxlength="20">
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="seach-history" v-if="!sitelistShow">
      <div class="title flex">
        <div>搜索历史</div>
        <img :src="deleteImg" class="delete-img" @click="deleteClick">
      </div>
      <div class="search-wrap flex">
        <div class="search-item" v-for="(item, index) in searchArr" :key="index" @click="searchitemClick(item)">{{item}}</div>
      </div>
    </div>
    <div class="site-item_list" v-if="sitelistShow">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished"  finished-text="没有更多了" @load="onLoad">
          <div class="site-item VDS-ShadowBg_home flex" v-for="(item, index) in siteList" :key="index">
            <img v-if="item.masterImg" class="sitezz-img" :src="item.masterImg">
            <img v-else-if="item.stationTagCode == 'FAILURE'" class="sitezz-img" :src="sitefailure">
            <img v-else class="sitezz-img" :src="sitezz">
            <div class="width_full">
              <div class="site-item_right  flex">
                <div class="site-item_one flex">
                  <div class="flex" style="height: 20px">
                    <div class="stationName" style="height: 20px"  v-if="item.stationType && item.stationType != '---' && item.hasMonitor != 0">{{item.stationName | ellipsis8 }}</div>
                    <div class="stationName" style="height: 20px"  v-else-if="item.stationType && item.stationType != '---'">{{item.stationName | ellipsis10 }}</div>
                    <div class="stationName" style="height: 20px" v-else>{{item.stationName}}</div>
                    <van-image v-if="item.hasMonitor != 0" :src="item.monitorStatus != 0 ? require('@/assets/img/site/live_onLine.png') : require('@/assets/img/site/live_outLine.png')" width="20" height="20" style="margin-top: -2px;margin-left: 3px"></van-image>
                  </div>
                  <div class="site-item_status" v-if="item.stationType && item.stationType != '---'"
                       :class="{'site-item_status_one':item.stationType == '优质站点',
                              'site-item_status_two':item.stationType == '问题站点',
                              'site-item_status_three':item.stationType == '普通站点',
                              'site-item_status_four':item.stationType == '低效站点',
                              'site-item_status_three':item.stationType == '普通站点+',
                              'site-item_status_five':item.stationType == '优质站点+',}">
                    {{item.stationType}}
                  </div>
                </div>
                <div class="stationLocate">{{item.stationLocate}}</div>
                <div class="flex">
                  <div class="site-item_tag" v-if="item.stationTag" :class="{'site-item_failure': item.stationTagCode == 'FAILURE'}">{{item.stationTag}}</div>
                  <div class="site-item_tag site-item_performanceRange" v-if="item.performanceRange && item.performanceRange != '---'">{{item.performanceRange}}</div>
                  <div class="site-item_tag" v-if="item.stationStatus" 
                    :class="{
                      'site-item_stationStatus_one':item.stationStatus == '考察中',
                      'site-item_stationStatus_two':item.stationStatus == '加盟中',
                      'site-item_stationStatus_three':item.stationStatus == '已加盟',
                      'site-item_stationStatus_four':item.stationStatus == '已签约',
                      'site-item_stationStatus_five': (item.stationStatus == '签约未通过' || item.stationStatus == '加盟未通过' ) }"
                  >{{item.stationStatus}}</div>
                  <!-- :class="{ 'borderColor' : borderColor, 'fontColor': fontColor}" -->
                </div>
              </div>
              <div v-if="isShow_SiteList_ToBeAudited" style="background-color: #F2F2F2;width: 100%;height: 1px;margin-top: 12px"></div>
              <div style="width: 100%;height: 24px;margin-top: 12px;">
                <div class="toStation" v-if="userRole == 0 && (item.stationStatusCode == 'INSPECTION_' || item.stationStatusCode == 'JOIN_FAIL')" @click="editClick(item.stationNo)">编辑站点</div>
                <div class="toStation" v-else @click="siteDetailClick(item.stationNo, item.marketerNo, item.stationStatusCode)">查看站点</div>
                
                <div v-if="item.hasMonitor != 0" @click.stop="monitoringPageClick(item.stationNo)" class="toLive">查看监控</div>
              </div>
            </div>

          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import common from '@/utils/common'
import {mapGetters} from 'vuex'
import { getMySitePageQuery } from '@/utils/api'
import {Page_Audit_Mysite} from "../../store/mutation-types";
export default {
  computed:{
    ...mapGetters([
      'isShow_SiteList_ToBeAudited'
    ])
  },
  filters: {
    ellipsis8(value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    },
    ellipsis10(value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    },
  },
  data() {
    return{
      value: null,
      deleteImg: require('@/assets/img/site/delete.png'),
      sitezz: require('@/assets/img/site/sitezz.png'),
      sitefailure: require('@/assets/img/site/sitefailure.png'),
      pageIndex: 1,
      pageSize: 10,
      isLoading: false,
      loading: false,
      finished: false,
      siteList: [],
      userRole: common.getItem('userRole'),
      sitelistShow: false,
      searchArr: [],
      
    }
  },
  watch:{
    value(val){
      if(!val){
        this.sitelistShow = false
      }
    },
  },
  mounted() {
    this.searchArr = JSON.parse(common.getItem('searchArr'))  
  },
  methods:{
    searchitemClick(item){
      this.value = item
      this.onSearch()
    },
    searchFilter() {
      if(!this.value) return
      let flag = false
      if(this.searchArr.length != 0){
        for(let [index,obj] of this.searchArr.entries()){
          if(this.value == obj){
            this.searchArr.splice(index, 1)
            this.searchArr.unshift(this.value)
            flag = true
          }
        }
      }
      if(!flag){
        this.searchArr.unshift(this.value)
      }
      if(this.searchArr.length > 20){
        this.searchArr.pop()
      }
      common.setItem('searchArr', JSON.stringify(this.searchArr) )
    },
    onSearch(){
      this.searchFilter()
      getMySitePageQuery({
        pageType: this.$route.query.pageType,
        conditions: this.value,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res=>{
         if(res.code === 200){
          if(res.data && res.data.businessCode == 200){
            this.sitelistShow = true
            this.finished = !res.data.pageModel.hasNextPage;
            if (this.pageIndex == 1) {
              this.siteList = res.data.pageModel.list;
            } else {
              this.siteList = this.siteList.concat(res.data.pageModel.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }

            var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
            var tokenUrl = '?Authorization=' + common.getItem('wtToken')
            for(let obj of this.siteList){
              if(obj.masterImg && obj.masterImg.indexOf('Authorization') < 0){
                obj.masterImg = baseUrl + obj.masterImg  + tokenUrl
              }
            }
          }
         }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.siteList = []
      this.pageIndex = 1
      this.onSearch()
    },
    onLoad () {
      this.onSearch()
    },
    deleteClick() {
      this.searchArr = []
      localStorage.removeItem('searchArr')
    },
    editClick(stationNo) {
      this.$router.push({name: 'newsiteList', query:{stationNo: stationNo}})
    },
    siteDetailClick (stationNo, marketerNo, stationStatusCode) {
      // 考察中直接跳转 提交加盟页面
      if (stationStatusCode == 'INSPECTION_') {

        if (marketerNo == common.getItem('uniqueNo')) { // 是自己建的
          this.$store.dispatch('keepAliveState/addKeepAliveAction', 'sitedetail')
          common.setItem("pageType",this.$route.query.pageType)
          this.$router.push({ name: 'sitedetail', query: { stationNo: stationNo,pageType:this.$route.query.pageType } })
        } else {
          this.$router.push({ name: 'audit', query: { stationNo: stationNo,from:Page_Audit_Mysite } })
        }
      } else {
        this.$router.push({ name: 'waitingSign', query: { stationNo: stationNo } })
      }
    },
    monitoringPageClick (stationNo) {
      this.$router.push({ name: 'monitoringPage', query: { 'stationNo': stationNo } })
    },
  }
}
</script>

<style lang="less" scoped>
.searchsite{
  min-height: 100%;
  background: #F7F8F9;
  .flex{
    display: flex;
  }
  .delete-img{
    width: 0.2rem;
    height: 0.24rem;
  }
  .seach-history{
    padding: 0.34rem 0.3rem;
    .title{
      font-size: 0.3rem;
      color: #4A4A4A;
      justify-content: space-between;
      align-items: center;
    }
    .search-wrap{
      padding: 0.24rem 0;
      flex-wrap: wrap;
    }
    .search-item{
      font-size: 0.24rem;
      color: #4A4A4A;
      padding: 0.1rem 0.24rem;
      border-radius: 28px;
      border: 0.01rem solid #EBECED;
      margin-right: 0.2rem;
      margin-bottom: 0.24rem;
    }
  }
  .van-search__content{
    border-radius: 30px;
    border: 1px solid #3F7C53;
  }
  .van-search__action{
    color: #3F7C53;
  }
  .van-search .van-cell{
    padding: 0.05rem 0 0.05rem;
  }
  .sitezz-img{
    width: 1.36rem;
    height: 1.36rem;
    flex: 0 0 1.36rem;
    border-radius: 0.08rem;
  }
  .VDS-ShadowBg_home {
    border-radius: 4px;
    box-shadow: 0px 2.5px 5px 5px rgba(0, 0, 0, 0.04);
  }
  .toStation {
    width: 72px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: white;
    text-align: center;
    border-radius: 12px;
    background-color: #3f7c53;
    float: right;
  }
  .toLive {
    margin-right: 15px;
    width: 72px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #3f7c53;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(63, 124, 83, 0.1);
    float: right;
  }
  .site-item_list {
    padding-bottom: 55px;
    margin: 0 0.3rem;
    .site-item {
      margin-top: 12px;
      border-radius: 4px;
      padding: 0.3rem 0.3rem 0.4rem;
      .logo-img {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.3rem;
      }
      .site-item_right {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        .site-item_one {
          height: 16px;
          line-height: 16px;
          justify-content: space-between;
          align-items: flex-start;
          .stationName {
            margin-left: 10px;
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
        }
        .site-item_tag {
          margin-left: 8px;
          color: #3f7c53;
          border: solid 1px #D9E4DD;
          height: 20px;
          line-height: 20px;
          border-radius: 4px;
          font-size: 12px;
          padding: 0 10px;
        }
        .site-item_performanceRange{
          color: #3F7C53;
          border-color: #D9E4DD;
        }
        .site-item_status {
          font-size: 12px;
          height: 20px;
          line-height: 20px;
          padding: 0 15px;
          border-radius: 2px;
        }
        .site-item_failure{
          color: #999;
          border-color: rgba(0,0,0,0.1); 
        }
        .site-item_status_one {
          color: #56cb8f;
          background-color: #e0f5eb;
        }
        .site-item_status_two {
          color: #f4664a;
          background-color: #ffeae6;
        }
        .site-item_status_three {
          color: #3874db;
          background-color: rgba(56, 116, 219, 0.1);
        }
        .site-item_status_four {
          color: #999999;
          background-color: rgba(153, 153, 153, 0.1);
        }
        .site-item_status_five{
          color:#fff;
          background: linear-gradient(155deg, #FCEAAE 0%, #E98D14 100%);
        }
        .site-item_stationStatus_one{
          color: #F6AA2E;
          border-color: #FFEFCB; 
        }
        .site-item_stationStatus_two{
          color: #F6AA2E; 
          border-color: #FFEFCB; 
        }
        .site-item_stationStatus_three{
          color: #56CB8F; 
          border-color: #E0F5EB; 
        }
        .site-item_stationStatus_four{
          color: #56CB8F; 
          border-color: #E0F5EB; 
        }
        .site-item_stationStatus_five{
          color: #F4664A; 
          border-color: #FFEAE6 ; 
        }
        .stationLocate {
          margin-left: 10px;
          font-size: 13px;
          color: #999999;
          padding: 0.1rem 0 0.1rem;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
}
</style>

<style lang="less">
.searchsite{
  .van-field__left-icon .van-icon, .van-field__right-icon .van-icon{
    font-size: 0.35rem;
  }
}
</style>